/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../images/icon.png";
import { Link } from "gatsby";
import {
	AnnotationIcon,
	GlobeAltIcon,
	LightningBoltIcon,
	MailIcon,
	ScaleIcon,
} from "@heroicons/react/outline";
import Footer from "../components/footer";
import vonSpradlin from "../images/vonSpradlin.png";
import randallWilson from "../images/randallWilson.png";

const navigation = [
	{ name: "Practice areas", href: "/practice-areas" },
	{ name: "Attorneys", href: "/attorneys" },
	{ name: "Locations", href: "/locations" },
	{ name: "About", href: "/about" },
];

const bankruptcyFeatures = [
	{
		id: 1,
		name: "Eliminate your debt without repaying it",
		description:
			"Chapter 7 bankruptcy eliminates credit card debt, personal loans, medical bills, payday loans and more.",
		icon: GlobeAltIcon,
	},
	{
		id: 2,
		name: "Keep your home, cars, and other property",
		description:
			"Bankruptcy allows you to exempt certain property, whether it be a house, car, 401(k), or other property.",
		icon: ScaleIcon,
	},
	{
		id: 3,
		name: "Pay back all or a portion of your debt over a set period of time",
		description:
			"Catch up mortgage payments and pay off your car using payments that meet your budget.",
		icon: LightningBoltIcon,
	},
];
const criminalDefenseFeatures = [
	{
		id: 1,
		name: "DUI & DWI",
		description:
			"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
		icon: AnnotationIcon,
	},
	{
		id: 2,
		name: "Drug offense",
		description:
			"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
		icon: MailIcon,
	},
];
const divorceFeatures = [
	{
		id: 1,
		name: "Contested and uncontested divorce",
		description:
			"When it comes to divorce cases, the devil is in the details. We will streamline the process and prepare a comprehensive settlement agreement.",
		icon: AnnotationIcon,
	},
	{
		id: 1,
		name: "Child support",
		description:
			"Ensuring sure your children receive the support they need and deserve can be overwhelming. Get help applying for child support services or when you've fallen behind in payments.",
		icon: AnnotationIcon,
	},
	{
		id: 1,
		name: "Adoption",
		description:
			"We've been helping families build and grow through adoptions for over 20 years.",
		icon: AnnotationIcon,
	},
];
// TODO: add a subsubsection for business bankruptcies
export default function Example() {
	return (
		<>
			<div className="relative bg-white overflow-hidden">
				<div className="max-w-7xl mx-auto">
					<div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
						<svg
							className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
							fill="currentColor"
							viewBox="0 0 100 100"
							preserveAspectRatio="none"
							aria-hidden="true"
						>
							<polygon points="50,0 100,0 50,100 0,100" />
						</svg>

						<Popover>
							<div className="relative pt-6 px-4 sm:px-6 lg:px-8">
								<nav
									className="relative flex items-center justify-between sm:h-10 lg:justify-start"
									aria-label="Global"
								>
									<div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
										<div className="flex items-center justify-between w-full md:w-auto">
											<a href="#">
												<span className="sr-only">Workflow</span>
												<img className="h-8 w-auto sm:h-10" src={logo} />
											</a>
											<div className="-mr-2 flex items-center md:hidden">
												<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
													<span className="sr-only">Open main menu</span>
													<MenuIcon className="h-6 w-6" aria-hidden="true" />
												</Popover.Button>
											</div>
										</div>
									</div>
									<div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
										{navigation.map((item) => (
											<Link
												key={item.name}
												to={item.href}
												className="font-medium text-gray-500 hover:text-gray-900"
											>
												{item.name}
											</Link>
										))}
									</div>
								</nav>
							</div>

							<Transition
								as={Fragment}
								enter="duration-150 ease-out"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="duration-100 ease-in"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Popover.Panel
									focus
									className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
								>
									<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
										<div className="px-5 pt-4 flex items-center justify-between">
											<div>
												<img
													className="h-8 w-auto"
													src="https://tailwindui.com/img/logos/workflow-mark-red-600.svg"
													alt=""
												/>
											</div>
											<div className="-mr-2">
												<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
													<span className="sr-only">Close main menu</span>
													<XIcon className="h-6 w-6" aria-hidden="true" />
												</Popover.Button>
											</div>
										</div>
										<div className="px-2 pt-2 pb-3 space-y-1">
											{navigation.map((item) => (
												<a
													key={item.name}
													href={item.href}
													className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
												>
													{item.name}
												</a>
											))}
										</div>
										<a
											href="tel://12058332589"
											className="block w-full px-5 py-3 text-center font-medium text-red-600 bg-gray-50 hover:bg-gray-100"
										>
											Call now
										</a>
										{/* <a
											href="#"
											className="block w-full px-5 py-3 text-center font-medium text-red-600 bg-gray-50 hover:bg-gray-100"
										>
											Get directions
										</a> */}
									</div>
								</Popover.Panel>
							</Transition>
						</Popover>

						<main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
							<div className="sm:text-center lg:text-left">
								<h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
									<span className="block text-red-600 xl:inline">
										Protecting your rights
									</span>{" "}
									<span className="block xl:inline">since 2000</span>
								</h1>
								<p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
									Get professional guidance and solutions regardless of your
									legal needs.
								</p>
								<div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
									<div className="rounded-md shadow">
										<a
											href="#"
											className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10"
										>
											Request a free consultation
										</a>
									</div>
									<div className="mt-3 sm:mt-0 sm:ml-3">
										<Link
											to="/practice-areas"
											className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 md:py-4 md:text-lg md:px-10"
										>
											Learn more
										</Link>
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
				<div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
					{/* <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="4-attorneys-table-books.png"
          alt=""
        /> */}
					<iframe
						title="commercial"
						className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
						src="https://player.vimeo.com/video/274712493"
						allowFullScreen
					></iframe>
				</div>
			</div>

			<div className="py-16 overflow-hidden lg:py-24">
				<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
					<svg
						className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
						width={404}
						height={784}
						fill="none"
						viewBox="0 0 404 784"
						aria-hidden="true"
					>
						<defs>
							<pattern
								id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={784}
							fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
						/>
					</svg>

					<div className="relative">
						<h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							We don't just know the law. We understand it.
						</h2>
						<p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
							You need an experienced attorney that will protect your rights and
							obtain the best results for you.
						</p>
					</div>

					<div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
						<div className="relative">
							<h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
								Struggling with debt?
							</h3>
							<p className="mt-3 text-lg text-gray-500">
								Whether you're facing foreclosure, repossession, or not enough
								money to pay your bills, there are options to help you get back
								on your feet with get a fresh start.
							</p>

							<dl className="mt-10 space-y-10">
								{bankruptcyFeatures.map((item) => (
									<div key={item.id} className="relative group">
										<dt>
											<div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 group-hover:bg-red-600 text-white">
												<item.icon className="h-6 w-6" aria-hidden="true" />
											</div>
											<p className="ml-16 text-lg leading-6 font-medium text-gray-900">
												{item.name}
											</p>
										</dt>
										<dd className="mt-2 ml-16 text-base text-gray-500">
											{item.description}
										</dd>
									</div>
								))}
							</dl>
						</div>

						<div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
							<svg
								className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
								width={784}
								height={404}
								fill="none"
								viewBox="0 0 784 404"
							>
								<defs>
									<pattern
										id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
										x={0}
										y={0}
										width={20}
										height={20}
										patternUnits="userSpaceOnUse"
									>
										<rect
											x={0}
											y={0}
											width={4}
											height={4}
											className="text-gray-200"
											fill="currentColor"
										/>
									</pattern>
								</defs>
								<rect
									width={784}
									height={404}
									fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
								/>
							</svg>
							<img
								className="relative mx-auto"
								width={490}
								src="https://image.shutterstock.com/shutterstock/photos/550887700/display_1500/stock-photo-young-caucasian-family-having-debt-problems-not-able-to-pay-out-their-loan-female-in-glasses-and-550887700.jpg"
								alt=""
							/>
						</div>
					</div>

					<div className="relative mt-12 sm:mt-16 lg:mt-10">
						<div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
							<div className="">
								<Link
									to="/practice-areas/bankruptcy/consumer"
									className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-500 hover:bg-red-600 md:py-4 md:text-lg md:px-10"
								>
									Learn more
								</Link>
							</div>
						</div>
					</div>

					<svg
						className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
						width={404}
						height={784}
						fill="none"
						viewBox="0 0 404 784"
						aria-hidden="true"
					>
						<defs>
							<pattern
								id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={784}
							fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
						/>
					</svg>

					<div className="relative mt-12 sm:mt-16 lg:mt-24">
						<div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
							<div className="lg:col-start-2">
								<h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
									Have you been charged with a crime?
								</h3>
								<p className="mt-3 text-lg text-gray-500">
									You need an experienced criminal defense attorney to protect
									your rights and freedom when you get charged with a serious
									criminal offense.
								</p>

								<dl className="mt-10 space-y-10">
									{criminalDefenseFeatures.map((item) => (
										<div key={item.id} className="relative">
											<dt>
												<div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
													<item.icon className="h-6 w-6" aria-hidden="true" />
												</div>
												<p className="ml-16 text-lg leading-6 font-medium text-gray-900">
													{item.name}
												</p>
											</dt>
											<dd className="mt-2 ml-16 text-base text-gray-500">
												{item.description}
											</dd>
										</div>
									))}
								</dl>
							</div>

							<div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
								<svg
									className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
									width={784}
									height={404}
									fill="none"
									viewBox="0 0 784 404"
									aria-hidden="true"
								>
									<defs>
										<pattern
											id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
											x={0}
											y={0}
											width={20}
											height={20}
											patternUnits="userSpaceOnUse"
										>
											<rect
												x={0}
												y={0}
												width={4}
												height={4}
												className="text-gray-200"
												fill="currentColor"
											/>
										</pattern>
									</defs>
									<rect
										width={784}
										height={404}
										fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
									/>
								</svg>
								<img
									className="relative mx-auto"
									width={490}
									src="https://image.shutterstock.com/shutterstock/photos/1144500824/display_1500/stock-photo-policeman-arrests-the-car-thief-on-road-1144500824.jpg"
									alt=""
								/>
							</div>
						</div>
					</div>

					<div className="relative mt-12 sm:mt-16 lg:mt-10">
						<div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
							<div className="lg:col-start-2">
								<Link
									to="/practice-areas/criminal-defense"
									className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-500 hover:bg-red-600 md:py-4 md:text-lg md:px-10"
								>
									Learn more
								</Link>
							</div>
						</div>
					</div>

					{/* new section start */}

					<div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
						<div className="relative">
							<h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
								We can help you in all family law matters
							</h3>
							<p className="mt-3 text-lg text-gray-500">
								Whether it's helping you through legal matters involving
								adoption, divorce, or child support, you need attorneys who not
								only understand your situation but who also will fight to get
								the results you deserve.
							</p>

							<dl className="mt-10 space-y-10">
								{divorceFeatures.map((item) => (
									<div key={item.id} className="relative group">
										<dt>
											<div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 group-hover:bg-red-600 text-white">
												<item.icon className="h-6 w-6" aria-hidden="true" />
											</div>
											<p className="ml-16 text-lg leading-6 font-medium text-gray-900">
												{item.name}
											</p>
										</dt>
										<dd className="mt-2 ml-16 text-base text-gray-500">
											{item.description}
										</dd>
									</div>
								))}
							</dl>
						</div>

						<div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
							<svg
								className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
								width={784}
								height={404}
								fill="none"
								viewBox="0 0 784 404"
							>
								<defs>
									<pattern
										id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
										x={0}
										y={0}
										width={20}
										height={20}
										patternUnits="userSpaceOnUse"
									>
										<rect
											x={0}
											y={0}
											width={4}
											height={4}
											className="text-gray-200"
											fill="currentColor"
										/>
									</pattern>
								</defs>
								<rect
									width={784}
									height={404}
									fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
								/>
							</svg>
							<img
								className="relative mx-auto"
								width={490}
								src="https://image.shutterstock.com/shutterstock/photos/550887700/display_1500/stock-photo-young-caucasian-family-having-debt-problems-not-able-to-pay-out-their-loan-female-in-glasses-and-550887700.jpg"
								alt=""
							/>
						</div>
					</div>

					<div className="relative mt-12 sm:mt-16 lg:mt-10">
						<div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
							<div className="">
								<Link
									to="/practice-areas/family-law"
									className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-500 hover:bg-red-600 md:py-4 md:text-lg md:px-10"
								>
									Learn more
								</Link>
							</div>
						</div>
					</div>

					{/* new section end */}
				</div>
			</div>

			<Testimonials />

			<Footer />
		</>
	);
}

function Testimonials() {
	return (
		<section className="bg-red-800">
			<div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
				<div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-red-900 lg:pr-16">
					{/* <div className="md:flex-shrink-0">
						<img className="h-12" src={logo} alt="Tuple" />
					</div> */}
					<blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
						<div className="relative text-lg font-medium text-white md:flex-grow">
							<svg
								className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-600"
								fill="currentColor"
								viewBox="0 0 32 32"
								aria-hidden="true"
							>
								<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
							</svg>
							<p className="relative">
								&quot;A lawyer that treats you like a person and not just a pay
								day. It's rare, but Mr Keller pulls it off.&quot;
							</p>
						</div>
						<footer className="mt-8">
							<div className="flex items-start">
								<div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
									<img
										className="h-12 w-12 rounded-full"
										src={vonSpradlin}
										alt=""
									/>
								</div>
								<div className="ml-4">
									<div className="text-base font-medium text-white">
										V. Spradlin
									</div>
									<div className="text-base font-medium text-red-200">
										Gardendale, AL
									</div>
								</div>
							</div>
						</footer>
					</blockquote>
				</div>
				<div className="py-12 px-4 border-t-2 border-red-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
					{/* <div className="md:flex-shrink-0">
						<img className="h-12" src={logo} alt="Workcation" />
					</div> */}
					<blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
						<div className="relative text-lg font-medium text-white md:flex-grow">
							<svg
								className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-600"
								fill="currentColor"
								viewBox="0 0 32 32"
							>
								<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
							</svg>
							<p className="relative">
								&quot;This is a great law firm and is in a great location. I
								would recommend them to anyone who needs any legal help.&quot;
							</p>
						</div>
						<footer className="mt-8">
							<div className="flex items-start">
								<div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
									<img
										className="h-12 w-12 rounded-full"
										src={randallWilson}
										alt=""
									/>
								</div>
								<div className="ml-4">
									<div className="text-base font-medium text-white">
										R. Wilson
									</div>
									<div className="text-base font-medium text-red-200">
										Birmingham, AL
									</div>
								</div>
							</div>
						</footer>
					</blockquote>
				</div>
			</div>
		</section>
	);
}
