import React, { useEffect, useState } from "react";

function ContactForm() {
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [allowSubmissions, setAllowSubmissions] = useState(true);
	const onSubmit = (e) => {
		e.preventDefault();

		if (phone || email) {
			setAllowSubmissions(false);
		}
	};
	const [formValid, setFormValid] = useState(false);
	const onUpdatePhoneOrEmail = (e) => {
		let phoneValidator = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		let emailValidator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		console.log("here1");

		if (phoneValidator.test(e.target.value)) {
			setPhone(e.target.value);
			setFormValid(true);
		} else if (emailValidator.test(e.target.value)) {
			setEmail(e.target.value);
			setFormValid(true);
		}
	};
	return (
		<form action="#" method="POST" className="space-y-6" onSubmit={onSubmit}>
			<div>
				<label htmlFor="name" className="sr-only">
					Full name
				</label>
				<input
					type="text"
					name="name"
					id="name"
					autoComplete="name"
					placeholder="Full name"
					className="block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md"
					onChange={(e) => setName(e.target.value)}
					disabled={!allowSubmissions}
				/>
			</div>

			<div>
				<label htmlFor="mobile-or-email" className="sr-only">
					Mobile number or email
				</label>
				<input
					type="text"
					name="mobile-or-email"
					id="mobile-or-email"
					autoComplete="email"
					placeholder="Mobile number or email"
					required
					pattern="(^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)"
					className="block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md"
					onChange={onUpdatePhoneOrEmail}
					disabled={!allowSubmissions}
				/>
			</div>

			<div>
				<label htmlFor="password" className="sr-only">
					Brief message
				</label>
				<textarea
					id="password"
					name="password"
					placeholder="Brief description of legal concern or interest."
					autoComplete="current-password"
					className="block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md"
					onChange={(e) => setDescription(e.target.value)}
					disabled={!allowSubmissions}
				/>
			</div>

			<div>
				<button
					type="submit"
					disabled={!allowSubmissions}
					className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
				>
					Get help
				</button>
			</div>
		</form>
	);
}

export default ContactForm;
