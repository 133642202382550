import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import Header from "../components/header";
import Footer from "../components/footer";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function RequestFreeConsultation() {
	const [agreed, setAgreed] = useState(false);

	return (
		<>
			<Header />

			<div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
				<div className="relative max-w-xl mx-auto">
					<svg
						className="absolute left-full transform translate-x-1/2"
						width={404}
						height={404}
						fill="none"
						viewBox="0 0 404 404"
						aria-hidden="true"
					>
						<defs>
							<pattern
								id="85737c0e-0916-41d7-917f-596dc7edfa27"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={404}
							fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
						/>
					</svg>
					<svg
						className="absolute right-full bottom-0 transform -translate-x-1/2"
						width={404}
						height={404}
						fill="none"
						viewBox="0 0 404 404"
						aria-hidden="true"
					>
						<defs>
							<pattern
								id="85737c0e-0916-41d7-917f-596dc7edfa27"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={404}
							fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
						/>
					</svg>
					<div className="text-center">
						<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							Request a free consultation
						</h2>
						<p className="mt-4 text-lg leading-6 text-gray-500">
							If you have a legal problem, we offer a free initial consultation
							to come in and sit with us. We will review your case and give you
							our professional legal opinion and a quote for service.
						</p>
					</div>
					<div className="mt-12">
						<form
							action="#"
							method="POST"
							className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
						>
							<div>
								<label
									htmlFor="first-name"
									className="block text-sm font-medium text-gray-700"
								>
									First name
								</label>
								<div className="mt-1">
									<input
										type="text"
										name="first-name"
										id="first-name"
										autoComplete="given-name"
										className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div>
								<label
									htmlFor="last-name"
									className="block text-sm font-medium text-gray-700"
								>
									Last name
								</label>
								<div className="mt-1">
									<input
										type="text"
										name="last-name"
										id="last-name"
										autoComplete="family-name"
										className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div className="sm:col-span-2">
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Email
								</label>
								<div className="mt-1">
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div className="sm:col-span-2">
								<label
									htmlFor="phone-number"
									className="block text-sm font-medium text-gray-700"
								>
									Phone Number
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<div className="absolute inset-y-0 left-0 flex items-center">
										<label htmlFor="country" className="sr-only">
											Country
										</label>
										<select
											id="country"
											name="country"
											className="h-full py-0 pl-4 pr-8 border-transparent bg-transparent text-gray-500 focus:ring-red-500 focus:border-red-500 rounded-md"
										>
											<option>US</option>
											<option>CA</option>
											<option>EU</option>
										</select>
									</div>
									<input
										type="text"
										name="phone-number"
										id="phone-number"
										autoComplete="tel"
										className="py-3 px-4 block w-full pl-20 focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
										placeholder="+1 (555) 987-6543"
									/>
								</div>
							</div>

							<div className="sm:col-span-2">
								<label
									htmlFor="company"
									className="block text-sm font-medium text-gray-700"
								>
									Practice area
								</label>
								<div className="mt-1">
									<select
										id="location"
										name="location"
										className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
										defaultValue="Bankruptcy"
									>
										<option>Bankruptcy</option>
										<option>Family Law</option>
										<option>Criminal Defense</option>
										<option>Personal Injury</option>
										<option>Other</option>
									</select>
								</div>
							</div>
							<div className="sm:col-span-2">
								<label
									htmlFor="message"
									className="block text-sm font-medium text-gray-700"
								>
									Message
								</label>
								<div className="mt-1">
									<textarea
										id="message"
										name="message"
										rows={4}
										className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border border-gray-300 rounded-md"
										defaultValue={""}
									/>
								</div>
							</div>
							<div className="sm:col-span-2">
								<div className="flex items-start">
									<div className="flex-shrink-0">
										<Switch
											checked={agreed}
											onChange={setAgreed}
											className={classNames(
												agreed ? "bg-red-600" : "bg-gray-200",
												"relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
											)}
										>
											<span className="sr-only">Agree to policies</span>
											<span
												aria-hidden="true"
												className={classNames(
													agreed ? "translate-x-5" : "translate-x-0",
													"inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
												)}
											/>
										</Switch>
									</div>
									<div className="ml-3">
										<p className="text-base text-gray-500">
											By selecting this, you agree to the{" "}
											<a
												href="#"
												className="font-medium text-gray-700 underline"
											>
												Privacy Policy
											</a>{" "}
											and{" "}
											<a
												href="#"
												className="font-medium text-gray-700 underline"
											>
												Cookie Policy
											</a>
											.
										</p>
									</div>
								</div>
							</div>
							<div className="sm:col-span-2">
								<button
									type="submit"
									className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
								>
									Let's talk
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 border-t">
				<div className="divide-y-2 divide-gray-200">
					<div className="lg:grid lg:grid-cols-3 lg:gap-8">
						<h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
							Locations
						</h2>
						<div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
							<div>
								<h3 className="text-lg leading-6 font-medium text-gray-900">
									Birmingham
								</h3>
								<div className="mt-2 text-base text-gray-500">
									<p>315 Gadsden Hwy, Suite D</p>
									<p className="mt-1">Birmingham, AL 35235</p>
								</div>
							</div>
							<div>
								<h3 className="text-lg leading-6 font-medium text-gray-900">
									Pell City
								</h3>
								<div className="mt-2 text-base text-gray-500">
									<p>1917 Cogswell Avenue, Suite 3</p>
									<p className="mt-1">Pell City, Alabama 35125</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}

function Example({ weekday = "Monday" }) {
	const [enabled, setEnabled] = useState(false);

	return (
		<Switch.Group as="div" className="flex items-center">
			<Switch
				checked={enabled}
				onChange={setEnabled}
				className={classNames(
					enabled ? "bg-red-600" : "bg-gray-200",
					"relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
				)}
			>
				<span
					aria-hidden="true"
					className={classNames(
						enabled ? "translate-x-5" : "translate-x-0",
						"pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
					)}
				/>
			</Switch>
			<Switch.Label as="span" className="ml-3">
				<span className="text-sm font-medium text-gray-900">{weekday}</span>
			</Switch.Label>
		</Switch.Group>
	);
}
