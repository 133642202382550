import React from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import logo from "../../../images/icon.png";
import robbKellerPhoto from "../../../images/robert-keller.png";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import ContactForm from "../../../components/contactForm";
import { StaticImage } from "gatsby-plugin-image";
import { CheckIcon } from "@heroicons/react/outline";

const navigation = [
	{ name: "Product", href: "#" },
	{ name: "Features", href: "#" },
	{ name: "Marketplace", href: "#" },
	{ name: "Company", href: "#" },
];

function FamilyLaw() {
	return (
		<>
			<Header />

			<Jumbotron />

			<div className="py-16 md:pt-96 lg:pt-10 bg-gray-50 overflow-hidden">
				<div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
					<div className="text-base max-w-prose mx-auto lg:max-w-none">
						<h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
							Accuracy. Consistency. Results.
						</h2>
						<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							What makes us different
						</p>
					</div>
					<div className="relative z-10 max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-96 text-lg text-gray-500 space-y-4">
						<p>
							We think family law is one of the most interesting, exciting, and
							dynamic legal practices. Our legal team will help you overcome the
							ever-changing cultural, social, and economic issues affecting the
							American family.
						</p>
						<p>
							For every two marriages in a given year, one of those will end in
							divorce. More than half of children under the age of eighteen in
							Alabama are growing up in one-parent homes. As a result of this
							trend, we provide thorough and effective representation to our
							clients on important family matters.
						</p>
					</div>
					<div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
						<div className="relative z-10">
							<div className="prose prose-red text-gray-500 mx-auto lg:max-w-none">
								<h3>Choose the best family law attorney</h3>
								<p>
									You need a firm that is both competent in and enthusiastic
									about family law practice. A firm that can provide valuable
									assistance to help you resolve your marital and family
									conflicts, with a solid background in both family law theory
									and practice. That's why we strive to provide you with legal
									services comprehensively and understandably.
								</p>
								<p></p>

								<p>
									Start with a free evaluation, you have nothing to lose and
									everything to gain.
								</p>
							</div>
						</div>
						<div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-60 lg:max-w-none">
							<svg
								className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
								width={404}
								height={384}
								fill="none"
								viewBox="0 0 404 384"
								aria-hidden="true"
							>
								<defs>
									<pattern
										id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
										x={0}
										y={0}
										width={20}
										height={20}
										patternUnits="userSpaceOnUse"
									>
										<rect
											x={0}
											y={0}
											width={4}
											height={4}
											className="text-gray-200"
											fill="currentColor"
										/>
									</pattern>
								</defs>
								<rect
									width={404}
									height={384}
									fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
								/>
							</svg>
							<blockquote className="relative bg-white rounded-lg shadow-lg">
								<div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
									<img
										src={logo}
										alt="Russo, Keller &amp; White, P.C."
										className="h-8"
									/>
									<div className="relative text-lg text-gray-700 font-medium mt-8">
										<svg
											className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
											fill="currentColor"
											viewBox="0 0 32 32"
											aria-hidden="true"
										>
											<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
										</svg>
										<p className="relative">
											I've been helping residents across the State of Alabama,
											to take control of their debt. I take great pride in
											taking care of my clients and I always put forth the most
											aggressive representation for each of them.
										</p>
									</div>
								</div>
								<cite className="relative flex items-center sm:items-start bg-red-600 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
									<div className="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:transform sm:-translate-y-1/2">
										<img
											className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-red-300"
											src={robbKellerPhoto}
											alt=""
										/>
									</div>
									<span className="relative ml-4 text-red-300 font-semibold leading-6 sm:ml-24 sm:pl-1">
										<p className="text-white font-semibold sm:inline">
											Robert Keller
										</p>{" "}
										<p className="sm:inline">
											Owner at Russo, Keller &amp; White, P.C.
										</p>
									</span>
								</cite>
							</blockquote>
						</div>
					</div>
				</div>
			</div>

			<CTA />
			<CasesWeHandle />

			<Footer />
		</>
	);
}

export default FamilyLaw;

function Jumbotron() {
	return (
		<div className="relative bg-gray-800 overflow-visible">
			{/* <div
				className="hidden sm:block sm:absolute sm:inset-0"
				aria-hidden="true"
			>
				<svg
					className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
					width={364}
					height={384}
					viewBox="0 0 364 384"
					fill="none"
				>
					<defs>
						<pattern
							id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
							x={0}
							y={0}
							width={20}
							height={20}
							patternUnits="userSpaceOnUse"
						>
							<rect x={0} y={0} width={4} height={4} fill="currentColor" />
						</pattern>
					</defs>
					<rect
						width={364}
						height={384}
						fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
					/>
				</svg>
			</div> */}
			<div className="relative pt-6 pb-16 sm:pb-10">
				<main className="mt-16 sm:mt-10">
					<div className="mx-auto max-w-7xl">
						<div className="lg:grid lg:grid-cols-12 lg:gap-8">
							<div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
								<div>
									<h1 className="text-4xl tracking-tight font-extrabold text-white sm:leading-none lg:text-5xl xl:text-6xl">
										<span className="">
											When family matters become legal matters
										</span>{" "}
									</h1>
									<p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
										You need an experienced family law attorney you can count on
										to help you through adoption, child support, divorce and
										more.
									</p>
									{/* <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">
										Used by
									</p>
									<div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
										<div className="flex flex-wrap items-start justify-between">
											<div className="flex justify-center px-1">
												<img
													className="h-9 sm:h-10"
													src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg"
													alt="Tuple"
												/>
											</div>
											<div className="flex justify-center px-1">
												<img
													className="h-9 sm:h-10"
													src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
													alt="Workcation"
												/>
											</div>
											<div className="flex justify-center px-1">
												<img
													className="h-9 sm:h-10"
													src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
													alt="StaticKit"
												/>
											</div>
										</div>
									</div> */}
								</div>
							</div>
							<div className="mt-16 sm:mt-24 md:mt-4 lg:col-span-6 md:h-0 z-10">
								<div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden shadow">
									<div className="px-4 py-8 sm:px-10">
										<div>
											<p className="mt-1 text-2xl font-extrabold text-gray-900 sm:tracking-tight">
												Claim your free consultation
											</p>
											<p className="text-gray-600">
												Your initial consultation will be with a bar certified
												attorney qualified to handle your case from start to
												finish.
											</p>
										</div>

										<div className="mt-6">
											<ContactForm />
										</div>
									</div>
									<div className="px-4 py-6 bg-gray-100 border-t-2 border-gray-200 sm:px-10">
										<p className="text-xs leading-5 text-gray-500">
											By submitting this form, you agree to our{" "}
											<a
												href="#"
												className="font-medium text-gray-900 hover:underline"
											>
												Terms
											</a>
											.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}

const features = [
	{
		name: "Paternity action",
		description:
			"Used to establish whether someone is the child's biological father.",
	},
	{
		name: "Legal separation",
		description:
			"Retain certain marital rights and obligations which are extinguished after the divorce is final.",
	},
	{
		name: "Annulment",
		description:
			"Obtain a judicial decision that a valid marriage does not exist nor ever existed.",
	},
	{
		name: "Same-sex marriage",
		description:
			"Same-sex couples find themselves in the same legal position as heterosexual couples.",
	},
	{
		name: "Prenuptial agreement",
		description:
			"Coveres specific aspects of married life such as who will pay the bills and who will do the household chores.",
	},
	{
		name: "Adequate disclosure",
		description:
			"Prospective spouses have a duty to fully disclose their financial status.",
	},
	{
		name: "Adoption",
		description:
			"Understand your legal obligations and rights, advise you through 'home studies,' and represent you at adoption hearings.",
	},
	{
		name: "Divorce",
		description:
			"We strive to resolve your marriage with the least amount of additional damage and pain to all individuals affected.",
	},
];
// https://ptgmedia.pearsoncmg.com/imprint_downloads/informit/kaplan/0536173990_fm_bm.pdf -- legal book
// https://ptgmedia.pearsoncmg.com/imprint_downloads/informit/kaplan/0536173990_chap1_2_4.pdf#[0,{%22name%22:%22XYZ%22},-5,735,1] chapters 1-4

function CasesWeHandle() {
	return (
		<div className="bg-white">
			<div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
				<div className="max-w-3xl mx-auto text-center">
					<h2 className="text-3xl font-extrabold text-gray-900">
						Experienced in family matters
					</h2>
					<p className="mt-4 text-lg text-gray-500">
						We represent individuals and families through a range of Family Law
						matters in civil courts.
					</p>
				</div>
				<dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
					{features.map((feature) => (
						<div key={feature.name} className="relative">
							<dt>
								<CheckIcon
									className="absolute h-6 w-6 text-green-500"
									aria-hidden="true"
								/>
								<p className="ml-9 text-lg leading-6 font-medium text-gray-900">
									{feature.name}
								</p>
							</dt>
							<dd className="mt-2 ml-9 text-base text-gray-500">
								{feature.description}
							</dd>
						</div>
					))}
				</dl>
			</div>
		</div>
	);
}

function CTA() {
	return (
		<div className="relative bg-gray-800">
			<div className="h-56 bg-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
				<img
					className="w-full h-full object-cover"
					src="https://image.shutterstock.com/shutterstock/photos/1017665110/display_1500/stock-photo-multiracial-team-working-together-in-office-teamwork-on-laptop-analyzing-online-business-project-1017665110.jpg"
					alt=""
				/>

				{/* <StaticImage
					className="w-full h-full object-cover"
					src="../../../images/attorney-phone-1.png"
				/> */}
			</div>
			<div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
				<div className="md:ml-auto md:w-1/2 md:pl-10">
					<h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
						top notch support
					</h2>
					<p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
						We’re here to help
					</p>
					<p className="mt-3 text-lg text-gray-300">
						No two cases are identical in all respects. Our attention to detail
						allows us to create winning strategies that are tailor-made to suit
						your needs. Contact us immediately for your free consultation.
						{/* No matter how similar two cases may appear, no two cases are ever identical in all respects. */}
					</p>
					<div className="mt-8">
						<div className="inline-flex rounded-md shadow">
							<a
								href="#"
								className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
							>
								Schedule an appointment
								<ExternalLinkIcon
									className="-mr-1 ml-3 h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
