import React from "react";
import { LocationMarkerIcon, PhoneIcon } from "@heroicons/react/solid";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { StaticImage } from "gatsby-plugin-image";

const locations = [
	{
		name: "Birmingham",
		role: "Front-end Developer",
		address: "315 Gadsden Hwy, Suite D",
		address2: "Birmingham, AL 35235",
		twitterUrl: "#",
		linkedinUrl: "#",
		phone: "12058332589",
		googleUrl:
			"https://www.google.com/maps/dir/Russo%20White%20&%20Keller%20P.C.",
		image: (
			<StaticImage
				className="object-cover shadow-lg rounded-lg"
				src="../../images/birmingham.jpg"
				alt="315 Gadsden Hwy"
			/>
		),
		getDirections: "#",
	},
	{
		name: "Pell City",
		address: "1917 Cogswell Avenue",
		address2: "Pell City, AL 35125",
		image: (
			<StaticImage
				className="object-cover shadow-lg rounded-lg"
				src="../../images/1900s_Cogwell_Avenue_Pell_City_April_2014_2.jpg"
				alt="1900s Cogswell Avenue"
			/>
		),
		twitterUrl: "#",
		linkedinUrl: "#",
		phone: "12053625540",
		getDirections: "#",
	},
	// More people...
];

const people = [
	{
		name: "Lindsay Walton",
		role: "Front-end Developer",
		imageUrl:
			"https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
		twitterUrl: "#",
		linkedinUrl: "#",
	},
	// More people...
];

export default function Example() {
	return (
		<>
			<Header />
			<div className="bg-white">
				<div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
					<div className="space-y-12">
						<div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
							<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
								Our locations
							</h2>
							<p className="text-xl text-gray-500">
								Providing you with the aggressive legal representation you need
								with convenient locations.
							</p>
						</div>

						<ul
							role="list"
							className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
						>
							{locations.map((location) => (
								<li
									key={location.email}
									className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
								>
									<div className="flex-1 flex flex-col p-8">
										{location.image}
										<h3 className="mt-6 text-gray-900 text-sm font-medium">
											{location.name}
										</h3>
										<dl className="mt-1 flex-grow flex flex-col justify-center space-y-2">
											<dt className="sr-only">Address</dt>
											<dd className="text-gray-500 text-sm">
												{location.address}
												<br />
												{location.address2}
											</dd>
											<dt className="sr-only">Phone</dt>
											<dd className="text-gray-500 text-sm">
												{formatPhoneNumber(location.phone)}
											</dd>
										</dl>
									</div>
									<div>
										<div className="-mt-px flex divide-x divide-gray-200">
											<div className="w-0 flex-1 flex">
												<a
													href={location.getDirections}
													className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
												>
													<LocationMarkerIcon
														className="w-5 h-5 text-gray-400"
														aria-hidden="true"
													/>
													<span className="ml-3">Get directions</span>
												</a>
											</div>
											<div className="-ml-px w-0 flex-1 flex">
												<a
													href={`tel:${location.phone}`}
													className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
												>
													<PhoneIcon
														className="w-5 h-5 text-gray-400"
														aria-hidden="true"
													/>
													<span className="ml-3">Call</span>
												</a>
											</div>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}
let formatPhoneNumber = (str) => {
	//Filter only numbers from the input
	let cleaned = ("" + str).substr(1, 10).replace(/\D/g, "");

	//Check if the input is of correct length
	let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		return "(" + match[1] + ") " + match[2] + "-" + match[3];
	}

	return null;
};
