import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";

const metrics = [
	{
		id: 2,
		stat: "2500+",
		emphasis: "Criminal Defense",
		rest: "cases.",
	},
	{
		id: 3,
		stat: "1800+",
		emphasis: "Divorce and Family Law",
		rest: "cases.",
	},
	{
		id: 4,
		stat: "3000+",
		emphasis: "Bankruptcy",
		rest: "cases.",
	},
	{
		id: 1,
		stat: "2000",
		emphasis: "Founded",
		rest: "",
	},
];

function about() {
	return (
		<>
			<Header />

			<div className="relative bg-gray-900">
				<div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
					<div className="h-full w-full xl:grid xl:grid-cols-2">
						<div className="h-full xl:relative xl:col-start-2">
							{/* <img
								className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
								src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
								alt="People working on laptops"
							/> */}
							<StaticImage
								className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
								src="../../images/attorneys-talking.png"
							/>
							<div
								aria-hidden="true"
								className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
							/>
						</div>
					</div>
				</div>
				<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
					<div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
						<h2 className="text-sm font-semibold text-red-300 tracking-wide uppercase">
							About us
						</h2>
						<p className="mt-3 text-3xl font-extrabold text-white">
							Effective attorneys don’t just know the law; they understand it
						</p>
						<p className="mt-5 text-lg text-gray-300">
							When you hire our firm, you are not just hiring one lawyer, you
							are hiring a team that works zealously to obtain the best possible
							results for you. Regardless of your particular legal need, Russo,
							White &amp; Keller, P.C. can provide you with the legal assistance
							you need to get moving forward.
						</p>
						<div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
							{metrics.map((item) => (
								<p key={item.id}>
									<span className="block text-2xl font-bold text-white">
										{item.stat}
									</span>
									<span className="mt-1 block text-base text-gray-300">
										<span className="font-medium text-white">
											{item.emphasis}
										</span>{" "}
										{item.rest}
									</span>
								</p>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className="bg-white">
				<div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
					<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
						<span className="block">Looking for legal help?</span>
						<span className="block">Start with a free consultation today.</span>
					</h2>
					<div className="mt-8 flex justify-center">
						<div className="inline-flex rounded-md shadow">
							<Link
								href="/schedule-free-consultation"
								className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
							>
								Schedule an appointment
							</Link>
						</div>
						<div className="ml-3 inline-flex">
							<Link
								to="/practice-areas"
								className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200"
							>
								Learn more
							</Link>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default about;
