// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-legal-disclaimer-js": () => import("./../../../src/pages/about/legal-disclaimer.js" /* webpackChunkName: "component---src-pages-about-legal-disclaimer-js" */),
  "component---src-pages-attorneys-js": () => import("./../../../src/pages/attorneys.js" /* webpackChunkName: "component---src-pages-attorneys-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-index-2-js": () => import("./../../../src/pages/locations/index2.js" /* webpackChunkName: "component---src-pages-locations-index-2-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-practice-areas-bankruptcy-consumer-js": () => import("./../../../src/pages/practice-areas/bankruptcy/consumer.js" /* webpackChunkName: "component---src-pages-practice-areas-bankruptcy-consumer-js" */),
  "component---src-pages-practice-areas-bankruptcy-index-js": () => import("./../../../src/pages/practice-areas/bankruptcy/index.js" /* webpackChunkName: "component---src-pages-practice-areas-bankruptcy-index-js" */),
  "component---src-pages-practice-areas-criminal-defense-index-js": () => import("./../../../src/pages/practice-areas/criminal-defense/index.js" /* webpackChunkName: "component---src-pages-practice-areas-criminal-defense-index-js" */),
  "component---src-pages-practice-areas-family-law-index-js": () => import("./../../../src/pages/practice-areas/family-law/index.js" /* webpackChunkName: "component---src-pages-practice-areas-family-law-index-js" */),
  "component---src-pages-practice-areas-index-js": () => import("./../../../src/pages/practice-areas/index.js" /* webpackChunkName: "component---src-pages-practice-areas-index-js" */),
  "component---src-pages-schedule-free-consultation-js": () => import("./../../../src/pages/schedule-free-consultation.js" /* webpackChunkName: "component---src-pages-schedule-free-consultation-js" */)
}

