import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";

function commercial() {
	return (
		<>
			<Header />
			<iframe
				title="commercial"
				className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
				src="https://player.vimeo.com/video/274712493"
				allowFullScreen
			></iframe>
			<Footer />
		</>
	);
}

export default commercial;
