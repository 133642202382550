import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";

import { PhoneOutgoingIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { StaticImage } from "gatsby-plugin-image";
const locations = [
	{
		name: "Birmingham, AL 35235",
		role: "Front-end Developer",
		address: "315 Gadsden Hwy, Suite D",
		imageUrl:
			"https://lh3.googleusercontent.com/proxy/DH0Bs0hpF9bFbIXDlRFhFWKs7jk9y5ql3wycHh5ieyOO5C9eaRq8WNVqYuH7A9ZL1Dh-lS8D1azHrnWB6HtDqKK_f3ReV5UC628",
		twitterUrl: "#",
		linkedinUrl: "#",
		phone: "12058332589",
		googleUrl:
			"https://www.google.com/maps/dir/Russo%20White%20&%20Keller%20P.C.",
		image: (
			<StaticImage
				className="object-cover shadow-lg rounded-lg"
				src="../../images/attorney-phone-1.png"
				alt="315 Gadsden Hwy"
			/>
		),
	},
	{
		name: "Pell City, AL 35125",
		role: "Front-end Developer",
		address: "1917 Cogswell Avenue",
		imageUrl:
			"https://upload.wikimedia.org/wikipedia/commons/a/aa/1900s_Cogwell_Avenue_Pell_City_April_2014_2.jpg",
		image: (
			<StaticImage
				className="object-cover shadow-lg rounded-lg"
				src="../../images/1900s_Cogwell_Avenue_Pell_City_April_2014_2.jpg"
				alt="1900s Cogswell Avenue"
			/>
		),
		twitterUrl: "#",
		linkedinUrl: "#",
		phone: "12053625540",
	},
	// More people...
];

// We have over 75 years of combined legal experience serving residents across the State of Alabama,
// particularly in Jefferson, St. Clair, Blount and Shelby counties. We put forth the most aggressive representation for you
// .
// Effective attorneys don’t just know the law; they understand it. We work tirelessly to obtain the best possible results for you. So,
// regardless of your particular legal need, Russo, White & Keller, P.C. can provide you with the
// effective legal assistance to get you moving forward in the right direction.

// With  locations convenient to you, we will provide you with the legal assistance you need to get moving forward again.
function index() {
	return (
		<div>
			<Header />

			<div className="bg-white">
				<div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
					<div className="space-y-12">
						<div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
							<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
								Our Locations
							</h2>
							<p className="text-xl text-gray-500">
								Providing the aggressive legal representation you need with
								convenient locations.
							</p>
						</div>
						<ul
							role="list"
							className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
						>
							{/* <StaticImage
					className="w-full h-full object-cover"
					src="../../../images/attorney-phone-1.png"
				/> */}
							{locations.map((location) => (
								<li key={location.name}>
									<div className="space-y-4">
										<div className="aspect-w-3 aspect-h-2">
											{location.image}
											{/* <img
												className="object-cover shadow-lg rounded-lg"
												src={location.imageUrl}
												alt=""
											/> */}
										</div>

										<div className="space-y-2">
											<div className="text-lg leading-6 font-medium space-y-1">
												<h3>{location.address}</h3>
												<p className="text-red-600">{location.name}</p>
											</div>
											<ul role="list" className="flex space-x-5">
												<li>
													<a
														href={`tel://${location.phone}`}
														className="text-gray-400 hover:text-gray-500"
													>
														<PhoneOutgoingIcon className="w-5 h-5" />
													</a>
												</li>
												<li>
													<a
														href={location.googleUrl}
														className="text-gray-400 hover:text-gray-500"
													>
														<LocationMarkerIcon className="w-5 h-5" />
													</a>
												</li>
												{/* <li>
                        <a href={location.twitterUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href={location.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">LinkedIn</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li> */}
											</ul>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default index;
