import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { CheckIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";

const tiers = [
	{
		name: "Bankruptcy",
		href: "/practice-areas/bankruptcy/consumer",
		priceMonthly: 12,
		description:
			"Put an end to creditor harassment, eliminate business and personal debt and keep your property.",
		includedFeatures: [
			"Chapter 7",
			"Chapter 11",
			"Small business backuprtcy",
			"Creditor Harassment",
			"Stop foreclosure",
			"Stop repossessions",
			"Stop wage garnishments",
		],
	},
	{
		name: "Criminal Defense",
		href: "/practice-areas/criminal-defense",
		priceMonthly: 24,
		description:
			"Obtain acquittals, reduced charges, or get charges dropped completely.",
		includedFeatures: [
			"murder",
			"assault",
			"sex-related crimes",
			"failure to register",
			"theft",
			"robbery",
			"drug offenses",
			"trafficking",
			"DUI/BUI/WUI",
		],
	},
	{
		name: "Family Law",
		href: "/practice-areas/family-law",
		priceMonthly: 32,
		description:
			"Representation in divorce, property settlements, alimony, child support and prenuptial agreement.",
		includedFeatures: [
			"Contested divorce",
			"Uncontested divorce",
			"Child Support",
			"Adoption",
			"Contempt",
			"Property agreements",
			"Negotiations",
			"Petitions",
		],
	},
	{
		name: "Personal Injury",
		href: "/practice-areas/personal-injury",
		priceMonthly: 48,
		description:
			"Recieve compensatation for medical expenses, lost wages, pain and suffering.",
		includedFeatures: [
			"Automobile Accidents",
			"Defective Products",
			"Drug Injuries",
			"Semi-trailer Truck Accidents",
			"Wrongful Death",
		],
	},
];

function index() {
	return (
		<>
			<Header />

			<div className="bg-white">
				<div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
					<div className="sm:flex sm:flex-col sm:align-center">
						<h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
							Practice areas
						</h1>
						<p className="mt-5 text-xl text-gray-500 sm:text-center">
							Making sense of law and procedure is difficult. We are a
							full-service law firm with a range of legal services designed to
							protect you. Regardless of your particular legal needs, we take
							pride in helping you understand every aspect of your case or legal
							matter.
						</p>
					</div>
					<div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
						{tiers.map((tier) => (
							<div
								key={tier.name}
								className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
							>
								<div className="p-6">
									<h2 className="text-lg leading-6 font-medium text-gray-900">
										{tier.name}
									</h2>
									<p className="mt-4 text-sm text-gray-500 h-12">
										{tier.description}
									</p>

									<Link
										to={tier.href}
										className="mt-8 block w-full bg-red-600 border border-red-500 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-red-700"
									>
										Learn more
									</Link>
								</div>
								<div className="pt-6 pb-8 px-6">
									<h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
										Specialties
									</h3>
									<ul role="list" className="mt-6 space-y-4">
										{tier.includedFeatures.map((feature) => (
											<li key={feature} className="flex space-x-3">
												<CheckIcon
													className="flex-shrink-0 h-5 w-5 text-green-500"
													aria-hidden="true"
												/>
												<span className="text-sm text-gray-500">{feature}</span>
											</li>
										))}
									</ul>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default index;
