import React from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import logo from "../../../images/icon.png";
import robbKellerPhoto from "../../../images/robert-keller.png";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import ContactForm from "../../../components/contactForm";
import { StaticImage } from "gatsby-plugin-image";

const navigation = [
	{ name: "Product", href: "#" },
	{ name: "Features", href: "#" },
	{ name: "Marketplace", href: "#" },
	{ name: "Company", href: "#" },
];

function consumer() {
	return (
		<>
			<Header />

			<Jumbotron />

			<div className="py-16 md:pt-96 lg:pt-10 bg-gray-50 overflow-hidden">
				<div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
					<div className="text-base max-w-prose mx-auto lg:max-w-none">
						<h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
							Accuracy. Consistency. Results.
						</h2>
						<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							What makes us different
						</p>
					</div>
					<div className="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-96">
						<p className="text-lg text-gray-500">
							We know dealing with debt is difficult especially when you have
							been faced financial hardship. We are a full-service law firm with
							a range of bankruptcy services designed to give you a fresh start.
							We take pride in helping you understand every aspect of your case
							or legal issue.
						</p>
					</div>
					<div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
						<div className="relative z-10">
							<div className="prose prose-red text-gray-500 mx-auto lg:max-w-none">
								<h3>Wrestling with debt?</h3>
								<p>
									The US bankruptcy code is difficult to make sense of by
									yourself but there are many options available to get relief
									from existing debt. We will evaluate and consider all options
									available to you so that you can choose what works best for
									you and your budget.
								</p>
								<p>
									There are some basic qualifications you must meet and certain
									situations may dictate which filing is best for you. Take the
									guess-work out by allowing us to evaluate your situation and
									guide you through every step along the way.
								</p>

								<p>
									When you file for bankruptcy, the automatic stay goes into
									effect. The automatic stay prevents creditors from harassing
									you about mortgage debt, credit card debt, auto loan debt,
									medical bills, and all other debt while you're in bankruptcy.
									If creditors violate the automatic stay by contacting you
									about your debt, they can be held responsible.
									{/* It’s the legal way to avoid foreclosure or repossession and to begin managing out-of-control debt effectively. A number of factors determine if you are eligible to file a Chapter 13 bankruptcy. If you believe that a Chapter 13 bankruptcy might be best for you, speak right away with an experienced Chapter 13 bankruptcy lawyer at the Russo, White & Keller Law Firm. */}
								</p>
								<h3>Stop calls from creditors</h3>
								<p>
									Creditors seem to be relentless in their quest to force you to
									pay a debt. Often times, the original creditor will sell your
									debt to debt collectors that will harrass you in order to get
									you to pay them. Pressure from both creditors and debt
									collection agencies can come in the form of creditor
									harassment. We have several options at our disposal to put a
									stop to the harassment.
								</p>
							</div>
						</div>
						<div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-60 lg:max-w-none">
							<svg
								className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
								width={404}
								height={384}
								fill="none"
								viewBox="0 0 404 384"
								aria-hidden="true"
							>
								<defs>
									<pattern
										id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
										x={0}
										y={0}
										width={20}
										height={20}
										patternUnits="userSpaceOnUse"
									>
										<rect
											x={0}
											y={0}
											width={4}
											height={4}
											className="text-gray-200"
											fill="currentColor"
										/>
									</pattern>
								</defs>
								<rect
									width={404}
									height={384}
									fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
								/>
							</svg>
							<blockquote className="relative bg-white rounded-lg shadow-lg">
								<div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
									<img
										src={logo}
										alt="Russo, Keller &amp; White, P.C."
										className="h-8"
									/>
									<div className="relative text-lg text-gray-700 font-medium mt-8">
										<svg
											className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
											fill="currentColor"
											viewBox="0 0 32 32"
											aria-hidden="true"
										>
											<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
										</svg>
										<p className="relative">
											I've been helping residents across the State of Alabama,
											to take control of their debt. I take great pride in
											taking care of my clients and I always put forth the most
											aggressive representation for each of them.
										</p>
									</div>
								</div>
								<cite className="relative flex items-center sm:items-start bg-red-600 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
									<div className="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:transform sm:-translate-y-1/2">
										<img
											className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-red-300"
											src={robbKellerPhoto}
											alt=""
										/>
									</div>
									<span className="relative ml-4 text-red-300 font-semibold leading-6 sm:ml-24 sm:pl-1">
										<p className="text-white font-semibold sm:inline">
											Robert Keller
										</p>{" "}
										<p className="sm:inline">
											Owner at Russo, Keller &amp; White, P.C.
										</p>
									</span>
								</cite>
							</blockquote>
						</div>
					</div>
				</div>
			</div>

			<CTA />
			<FAQ />

			<Footer />
		</>
	);
}

export default consumer;

function Jumbotron() {
	return (
		<div className="relative bg-gray-800 overflow-visible">
			{/* <div
				className="hidden sm:block sm:absolute sm:inset-0"
				aria-hidden="true"
			>
				<svg
					className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
					width={364}
					height={384}
					viewBox="0 0 364 384"
					fill="none"
				>
					<defs>
						<pattern
							id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
							x={0}
							y={0}
							width={20}
							height={20}
							patternUnits="userSpaceOnUse"
						>
							<rect x={0} y={0} width={4} height={4} fill="currentColor" />
						</pattern>
					</defs>
					<rect
						width={364}
						height={384}
						fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
					/>
				</svg>
			</div> */}
			<div className="relative pt-6 pb-16 sm:pb-10">
				<main className="mt-16 sm:mt-10">
					<div className="mx-auto max-w-7xl">
						<div className="lg:grid lg:grid-cols-12 lg:gap-8">
							<div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
								<div>
									<h1 className="text-4xl tracking-tight font-extrabold text-white sm:leading-none lg:text-5xl xl:text-6xl">
										<span className="">Keep your property and</span>{" "}
										<span className="text-red-400 ">eliminate debt</span>
									</h1>
									<p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
										Put an end to creditor harassment with our legal services
										designed to eliminate most debts in one fell swoop.
									</p>
									{/* <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">
										Used by
									</p>
									<div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
										<div className="flex flex-wrap items-start justify-between">
											<div className="flex justify-center px-1">
												<img
													className="h-9 sm:h-10"
													src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg"
													alt="Tuple"
												/>
											</div>
											<div className="flex justify-center px-1">
												<img
													className="h-9 sm:h-10"
													src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
													alt="Workcation"
												/>
											</div>
											<div className="flex justify-center px-1">
												<img
													className="h-9 sm:h-10"
													src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
													alt="StaticKit"
												/>
											</div>
										</div>
									</div> */}
								</div>
							</div>
							<div className="mt-16 sm:mt-24 md:mt-4 lg:col-span-6 md:h-0 z-10">
								<div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden shadow">
									<div className="px-4 py-8 sm:px-10">
										<div>
											<p className="mt-1 text-2xl font-extrabold text-gray-900 sm:tracking-tight">
												Claim your free consultation
											</p>
											<p className="text-gray-600">
												Your initial consultation will be with a bar certified
												attorney qualified to handle your case from start to
												finish.
											</p>
										</div>

										<div className="mt-6">
											<ContactForm />
										</div>
									</div>
									<div className="px-4 py-6 bg-gray-100 border-t-2 border-gray-200 sm:px-10">
										<p className="text-xs leading-5 text-gray-500">
											By submitting this form, you agree to our{" "}
											<a
												href="#"
												className="font-medium text-gray-900 hover:underline"
											>
												Terms
											</a>
											.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}

const faqs = [
	{
		id: 1,
		question: "Will I be able to keep my house or car and other property?",
		answer:
			"In general, the answer is yes. Chapter 7 bankruptcy is designed to wipe out your debt. However, this is conditioned upon you not owning any property that is not exempt. Non-exempt property is sold by the bankruptcy court, converted to cash, and then used to pay back all or a portion of the debt that is owed by you. The bankruptcy law allows you to exempt your property, whether it be a home, car, 401(k), or other property. If your property is exempt, it generally cannot be used to pay your creditors any money. The exemptions differ for each piece of property that you own, and we will be able to explain to you in more detail about these exemptions. In Chapter 13 cases, this works a little different, and you'll need to contact us to discuss this.",
	},
	{
		id: 2,
		question: "What's a discharge?",
		answer:
			"A discharge is a court order which prohibits the collection of all debt which you listed in your bankruptcy petition. The discharge follows and protects you the rest of your life. There are certain debts that are excepted from discharge and that you still must pay despite a discharge in your bankruptcy case. Three of the most common debts that you still must pay after a discharge are federal and state income taxes, student loans, and child support and alimony. Another type of debt which you must repay despite receiving a discharge is debt that you have reaffirmed, such as a house or car.",
	},
	{
		id: 3,
		question:
			"Will I be able to get rid of property I no longer want or can afford?",
		answer:
			"In general, the answer is yes. Some debtors can no longer afford their house or car payments. In other cases, the house is just too big for them to keep up and they want to downsize. In still other cases, a car may keep breaking down and it is not worthwhile to continue to repair. Regardless of the circumstances, you can still surrender the property back to the mortgage company or to the lienholder. If you do so, the balance of the debt will be wiped out (discharged). In Chapter 13 cases, this works a little different, and you'll need to contact us to discuss this.",
	},
	{
		id: 4,
		question: "What if there is a co-signer on a debt of mine?",
		answer:
			"If there is a co-signer on a credit card, medical bill, personal loan, or other such bill, then the co-signer will be responsible for paying that bill once you have discharged your debt. This is also the case if there is a co-signer on a home mortgage or car note and you are surrendering the home or car. If, on the other hand, you are keeping the home or car, and you reaffirm the debt, then both you and the co-signer will remain obligated to pay the debt reaffirmed. In Chapter 13 cases, this works a little different, and you'll need to contact us to discuss this.",
	},
	// More questions...
];

function FAQ() {
	return (
		<div className="bg-white">
			<div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
				<div className="max-w-2xl lg:mx-auto lg:text-center">
					<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
						Frequently asked questions
					</h2>
					{/* <p className="mt-4 text-gray-500">
						Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
						Malesuada adipiscing sagittis vel nulla nec. Urna, sed a lectus
						elementum blandit et.
					</p> */}
				</div>
				<div className="mt-20">
					<dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
						{faqs.map((faq) => (
							<div key={faq.id}>
								<dt className="font-semibold text-gray-900">{faq.question}</dt>
								<dd className="mt-3 text-gray-500">{faq.answer}</dd>
							</div>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}

function CTA() {
	return (
		<div className="relative bg-gray-800">
			<div className="h-56 bg-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
				<img
					className="w-full h-full object-cover"
					src="https://image.shutterstock.com/shutterstock/photos/1017665110/display_1500/stock-photo-multiracial-team-working-together-in-office-teamwork-on-laptop-analyzing-online-business-project-1017665110.jpg"
					alt=""
				/>

				{/* <StaticImage
					className="w-full h-full object-cover"
					src="../../../images/attorney-phone-1.png"
				/> */}
			</div>
			<div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
				<div className="md:ml-auto md:w-1/2 md:pl-10">
					<h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
						top notch support
					</h2>
					<p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
						We’re here to help
					</p>
					<p className="mt-3 text-lg text-gray-300">
						No two cases are identical in all respects. Our attention to detail
						allows us to create winning strategies that are tailor-made to suit
						your needs. Contact us immediately for your free consultation.
						{/* No matter how similar two cases may appear, no two cases are ever identical in all respects. */}
					</p>
					<div className="mt-8">
						<div className="inline-flex rounded-md shadow">
							<a
								href="#"
								className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
							>
								Schedule an appointment
								<ExternalLinkIcon
									className="-mr-1 ml-3 h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
